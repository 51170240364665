@import "../../index.scss";
@import "../../pages/home/Home.scss";

.footer {
  grid-column: full-start / full-end;
  background: $tertiary;

  display: grid;
  justify-content: center;

  color: white;
  font-size: 1.5rem;
}

.main-logo {
  height: 100%;
  padding-top: 10px;
}
