// RESPONSIVE BREAKPOINTS
$bp-largest: 75em; //1200px
$bp-large: 62.5em; //1000/ 16px(1 rem)
$bp-medium: 50em; // 800px
$bp-small: 37.5em; // 600px
$bp-smallest: 28em; // 375px

//COLOR
$primary: #86b8b5;
$secondary: #5762d5;
$secondary-dark: #3f4abf;
$tertiary: #32292f;
$quaternary: #e25866;

$grey-light-1: #eaeaea;
$grey-light-2: #d2d2d2;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: "Montserrat", sans-serif;

  @media only screen and (max-width: $bp-largest) {
    font-size: 50%;
  }
}
