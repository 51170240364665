@import "../../index.scss";

.home {
  display: grid;
  grid-template-rows:
    calc(100vh - 6rem) 6rem minmax(min-content, 60vh) minmax(min-content, 50vh)
    minmax(min-content, 50vh) minmax(min-content, 50vh) minmax(
      min-content,
      50vh
    )
    6rem;
  grid-template-columns:
    [full-start] 1fr [center-start] repeat(
      8,
      [col-start] minmax(min-content, 14rem) [col-end]
    )
    [center-end] 1fr [full-end];
}
