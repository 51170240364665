@import "../../index.scss";
@import "../../pages/home/Home.scss";

.about {
  display: grid;
  grid-column: center-start / center-end;
  padding: 3rem;
  grid-template-rows: 13rem minmax(min-content, 1fr);
  grid-template-columns: repeat(
    auto-fit,
    minmax(30rem, 1fr)
  ); //auto fit meaning that css will make columns based on 25rem as min and 1fr for max
  grid-gap: 3rem;

  &__title {
    grid-column: 1/ -1;
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    &-header {
      font-size: 4rem;
      font-weight: 200;
    }

    &-divider {
      width: 28rem;
      height: 3px;
      background: $grey-light-2;
      margin-top: 10px;
    }
  }

  &__picture {
    display: grid;
  }

  &__bio {
    &-sentence {
      margin-top: 2rem;
      font-size: 1.5rem;
    }

    &-link {
      display: flex;
      margin-top: 20px;
    }
  }

  &__interest {
    &-sentence {
      margin-top: 2rem;
      font-size: 1.5rem;
    }
  }
}

.profile_pic {
  width: 100%;
}

.linkedin {
  width: 8rem;
}

.interest__list {
  margin-top: 1.5rem;
  margin-left: 3rem;
  font-size: 1.4rem;
}

.hello,
.personal_interest {
  font-size: 2.3rem;
}
