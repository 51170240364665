@import "../../index.scss";
@import "../../pages/contact/Contact.scss";

.contact-form {
  grid-column: full-start / full-end;
  // background: $primary;
  display: grid;
  flex-direction: column;

  grid-template-columns: 1fr repeat(auto-fit, minmax(min-content, 35rem)) 1fr;
  grid-template-rows: 13rem minmax(min-content, 6rem) max-content;

  &__sub_heading {
    text-align: center;
    font-size: 1.5rem;
    padding: 0 3rem;
  }

  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-title {
      font-size: 3.5rem;
    }

    &-divider {
      width: 30rem;
      height: 3px;
      background: $secondary;
      margin-top: 1rem;
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10%;

    input,
    textarea {
      height: 4rem;
      width: 100%;
      border-radius: 1px;
      border: solid 1px #ccc;
      margin-top: 2rem;
      font-family: inherit;
      font-style: italic;
      width: 96%;
      padding: 0 2%;
      line-height: 4rem;
      font-size: 1.5rem;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      resize: none;
    }
  }

  textarea {
    height: 8rem;
  }
}

.errorMessage {
  color: red;
}

.btn__item {
  color: #fff;
  background-color: $secondary;
  cursor: pointer;
  padding: 1.5rem 5rem;
  border-radius: 1rem;
  border: none;
  margin: 2rem 0;
  letter-spacing: 0.25rem;

  &:hover {
    background-color: $secondary-dark;
  }
}
