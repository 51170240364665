@import "../../index.scss";
.contact {
  display: grid;
  grid-template-rows: calc(100vh - 6rem);
  grid-template-columns:
    [full-start] 1fr [center-start] repeat(
      8,
      [col-start] minmax(min-content, 14rem) [col-end]
    )
    [center-end] 1fr [full-end];

  &__card {
    grid-column: full-start / full-end;
    background: $tertiary;
  }
}
