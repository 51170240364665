@import "../../index.scss";

.navbar {
  grid-column: full-start / full-end;
  grid-row: 2 / 3;
  z-index: 1;

  background: transparent;
  height: 6rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__list {
    display: flex;
    justify-content: space-between;

    li:not(:last-child) {
      margin-right: 2rem;
    }
  }
}

.list-item {
  list-style: none;
  font-size: 1.8rem;
  height: 100%;
  font-weight: 300;

  @media only screen and (max-width: $bp-smallest) {
    font-size: 1.4rem;
    font-weight: 600;
  }

  &__text {
    text-decoration: none;
    color: white;
    height: 100%;
    cursor: pointer;

    &:hover {
      border-radius: 5px;
      transition: all 0.2s;
      color: $tertiary;
    }
  }
}

//class to apply on scrool
.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  background-color: $primary;
}
