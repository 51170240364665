@import "../../index.scss";

.job {
  grid-column: 1 / -1;
  grid-row-gap: 1rem;
  display: grid;
  grid-template-rows: repeat(2, minmax(min-content, 5rem)) repeat(
      2,
      minmax(min-content, 8rem)
    );
  justify-items: center;

  @media only screen and (max-width: $bp-smallest) {
    grid-row-gap: 1rem;
  }

  h1 {
    font-size: 3rem;
    justify-self: center;
  }

  &__keywords {
    display: flex;

    @media only screen and (max-width: $bp-smallest) {
      justify-self: normal;
      justify-content: space-around;
    }

    li {
      list-style: none;
      margin-left: 5rem;
      font-size: 2rem;

      @media only screen and (max-width: $bp-smallest) {
        margin-left: 2rem;
      }

      &::before {
        content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: $quaternary; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
      }
    }
  }

  &__description {
    font-size: 1.5rem;
    text-align: center;
  }

  &-link {
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: repeat(2, minmax(min-content, 1fr));

    &__company {
      font-size: 1.8rem;
      &-prompt {
        font-weight: bold;
      }

      &-description {
      }
    }

    &__period {
      font-size: 1.8rem;
      &-prompt {
        font-weight: bold;
      }

      &-description {
      }
    }
  }
}
