@import "../../index.scss";
@import "../../pages/home/Home.scss";

.education {
  background: $grey-light-1;
  padding: 3rem;

  display: grid;
  grid-column: full-start / full-end;

  grid-template-rows: 13rem minmax(min-content, 1fr);
  &__title {
    grid-column: 1/ -1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-header {
      font-size: 4rem;
      font-weight: 200;
    }

    &-divider {
      width: 28rem;
      height: 3px;
      background: $primary;
      margin-top: 10px;
    }
  }

  &__paragraph {
    font-size: 1.5rem;
    text-align: center;
  }
}
