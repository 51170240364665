@import "../../index.scss";
@import "../../pages/home/Home.scss";

.skills {
  background: $grey-light-1;
  padding: 3rem;

  display: grid;
  grid-column: full-start / full-end;

  grid-template-rows: 13rem repeat(2, minmax(min-content, 6rem));
  &__title {
    grid-column: 1/ -1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-header {
      font-size: 4rem;
      font-weight: 200;
    }

    &-divider {
      width: 28rem;
      height: 3px;
      background: $secondary;
      margin-top: 10px;
    }
  }

  &__paragraph {
    font-size: 1.5rem;
    text-align: center;
  }
}

.top-used {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
}

.top-used__grid {
  margin-top: 2rem;
  display: grid;
  grid-column: 1/-1;
  grid-template-columns:
    1fr [top-used-start] repeat(2, minmax(min-content, 50rem)) [top-used-end]
    1fr;

  font-size: 1.4rem;
}

.top-used__list {
  grid-column: top-used-start / top-used-end;

  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, 50rem));
}

.frameworks,
.software {
  margin-top: 2rem;
}

.software-title,
.frameworks-title {
  text-align: center;
  height: 4rem;
  font-size: 2.3rem;
  @media only screen and(max-width: $bp-smallest) {
    font-size: 2rem;
  }
}
