@import "../../index.scss";
@import "../../pages/home/Home.scss";

.header {
  background: linear-gradient(rgba($tertiary, 0.93), rgba($primary, 0.93)),
    url("../images/bg.jpg");
  background-size: cover;
  background-position: center;
  grid-column: full-start/ full-end;
  grid-row: 1/ 3;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;

    &-name {
      font-size: 5rem;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.5rem;
      @media only screen and (max-width: $bp-largest) {
        font-size: 3.5rem;
      }
    }

    &-devider {
      width: 27rem;
      height: 2px;
      margin-top: 5px;
      margin-bottom: 10px;
      background: $primary;
    }

    &-title {
      font-size: 2.5rem;
      text-transform: uppercase;
      letter-spacing: 0.5rem;
      @media only screen and (max-width: $bp-largest) {
        font-size: 2rem;
      }
    }
  }
}

.profile {
  border-radius: 15rem;
}
